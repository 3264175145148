import React, { useState } from "react"
import { Section } from "../components/Section"
import { Container, ContentContainer } from "../components/Container"
import { buttonDefaultStyle } from "../components/Buttons"
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next"

export function Social() {
    const [allowNewsletter, setAllowNewsletter] = useState(false)

    const socials = [
        {
            link: "https://twitter.com/frameland",
            icon: "/assets/icon_twitter.png",
            name: "Twitter",
        },
        {
            link: "https://bsky.app/profile/frameland.bsky.social",
            icon: "/assets/icon_bluesky.png",
            name: "Bluesky",
        },
        {
            link: "https://www.tumblr.com/framelandgame",
            icon: "/assets/icon_tumblr.png",
            name: "Tumblr",
        },
        {
            link: "https://tiktok.com/@framelandgame",
            icon: "/assets/icon_tiktok.png",
            name: "Tiktok",
        },
        {
            link: "https://www.instagram.com/frameland_game/",
            icon: "/assets/icon_instagram.png",
            name: "Instagram",
        },
        {
            link: "https://facebook.com/framelandgame",
            icon: "/assets/icon_facebook.png",
            name: "Facebook",
        },
        {
            link: "https://mastodon.gamedev.place/@frameland",
            icon: "/assets/icon_mastodon.png",
            name: "Mastodon",
        },
    ]

    return (
        <Section bg="bg-fl-turkis-dark" color="text-fl-text" className="pt-0 pb-4">
            <Container>
                <ContentContainer className="max-w-sm pt-8 pb-4 mt-12 relative">
                    <div className="mx-auto mb-2 max-w-xs px-1">
                        <h2 className="text-lg uppercase tracking-wider text-fl-white text-center mb-0">
                            <Trans i18nKey="socialTitle">Stay up to date</Trans>
                        </h2>
                        <p className="max-w-xs mx-auto text-center">
                            <Trans i18nKey="socialDesc">No spam, major announcements only!</Trans>
                        </p>

                        <p className="font-bold mb-1 mt-6">Newsletter</p>
                        <Newsletter enabled={allowNewsletter} loadJQUery={loadJQuery} />
                        <p className="text-xs mt-3 text-gray-400">
                            <Trans i18nKey="socialPrivacy">
                                You can unsubscribe at any time by clicking the link in the footer
                                of our emails. For details see our&nbsp;
                                <Link to="/privacy" className="underline">
                                    privacy&nbsp;policy.
                                </Link>
                            </Trans>
                        </p>

                        <hr className="mt-6 block border-gray-300 absolute left-0 right-0 opacity-20" />
                    </div>
                </ContentContainer>
                <div className="mt-10 mb-0">
                    <h2 className="text-lg uppercase tracking-wider text-fl-white text-center mb-0">
                        <Trans i18nKey="socialHeadline">Or follow us here</Trans>
                    </h2>
                    <span className="flex items-center justify-center mt-2">
                        {socials.map(s => (
                            <a
                                key={s.name}
                                className="p-2 opacity-90 hover:opacity-100"
                                href={s.link}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={s.icon}
                                    className="inline mr-1"
                                    alt={s.name}
                                    title={s.name}
                                />
                            </a>
                        ))}
                    </span>
                </div>
            </Container>
        </Section>
    )

    function loadJQuery() {
        const scriptTag = document.createElement("script")
        scriptTag.src = "//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
        scriptTag.async = true
        scriptTag.onload = function () {
            initNewsletter()
        }
        document.body.appendChild(scriptTag)
    }

    function initNewsletter() {
        setAllowNewsletter(true)
        const jQuery = window.$
        ;(function ($) {
            window.fnames = new Array()
            window.ftypes = new Array()
            window.fnames[0] = "EMAIL"
            window.ftypes[0] = "email"
            window.fnames[1] = "FNAME"
            window.ftypes[1] = "text"
            window.fnames[2] = "LNAME"
            window.ftypes[2] = "text"
            window.fnames[3] = "ADDRESS"
            window.ftypes[3] = "address"
            window.fnames[4] = "PHONE"
            window.ftypes[4] = "phone"
            window.fnames[5] = "BIRTHDAY"
            window.ftypes[5] = "birthday"
        })(jQuery)
        var $mcj = jQuery.noConflict(true)
    }
}

function Newsletter({ enabled, loadJQUery }) {
    const { t } = useTranslation()
    return (
        <div id="mc_embed_signup2" className="mt-2">
            <form
                action="https://frameland.us12.list-manage.com/subscribe/post?u=f7414c81d651e4f34696a9013&amp;id=274cdbdba3&amp;f_id=0047b4e0f0"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_blank"
                rel="noreferrer"
                noValidate
            >
                <div id="mc_embed_signup_scroll">
                    <div className="mc-field-group">
                        <input
                            type="email"
                            name="EMAIL"
                            className="w-full text-sm p-2 text-gray-700"
                            id="mce-EMAIL"
                            placeholder="email@gmail.com"
                            required
                            defaultValue=""
                            onFocus={() => loadJQUery()}
                        />
                    </div>
                    <span id="mce-EMAIL-HELPERTEXT"></span>
                    <div className="absolute" style={{ left: -5000 }} aria-hidden="true">
                        <input
                            type="text"
                            name="b_f7414c81d651e4f34696a9013_274cdbdba3"
                            tabIndex="-1"
                            defaultValue=""
                        />
                    </div>
                    <div className="optionalParent">
                        <input
                            type="submit"
                            value={t("socialNewsletterJoin", "Join Newsletter")}
                            name="subscribe"
                            id="mc-embedded-subscribe"
                            className={`${buttonDefaultStyle} w-full rounded-t-none`}
                        />
                    </div>
                    <div id="mce-responses">
                        <div className="response hidden" id="mce-error-response"></div>
                        <div className="response hidden" id="mce-success-response"></div>
                    </div>
                </div>
            </form>
        </div>
    )
}
